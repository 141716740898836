export default {
  component: {
    exhibitorDirectory: 'exhibitor directory',
    exhibitorDetails: 'exhibitor details',
    productDirectory: 'product directory',
    productDetails: 'product details',
    homePage: 'home page'
  },
  subComponent: {
    priority: 'priority',
    standard: 'standard'
  },
  type:{
    exhibitor: 'exhibitor',
    product: 'product'
  },
  eventName: {
    addToShowPlan: 'Plan Added',
    removeFromShowPlan: 'Plan Removed',
    exhibitorClicked: 'Exhibitor Interacted',
    productInteracted: 'Product Interacted',
    exhibitorImpression: 'Exhibitor Impression',
    productImpression: 'Product Impression',
    documentImpression: 'Document Impression',
    documentDownloaded: 'Document Downloaded',
    digitalPackageInteracted: 'Digital Package Interacted',
    exhibitorContactInfo: 'Contact Info Interacted',
    documentClicked: 'Document Clicked',
    sortOrderSelected: 'Sort Order Selected',
    productClicked: 'Product Clicked',
    dashboardComponentViewed:'Dashboard Component Viewed',
    exhibitorDashboardInteracted:'Exhibitor Dashboard Interacted',
    tabSwitch: 'Tab Switch',
    exhibitorHubVisited: 'Exhibitor Hub Visited'
  },
  eventProperties: {
    exhibitorId : 'Exhibitor ID',
    exhibitorName: 'Exhibitor Name',
    packageType: 'Package Type',
    productArea: 'Product Area',
    component: 'Component',
    entityAdded: 'Entity Added',
    entityRemoved: 'Entity Removed',
    productId: 'Product ID',
    productName: 'Product Name',
    element: 'Interacted Element',
    subComponent: 'Slot Type',
    documentName: 'Document Name',
    documentType: 'Document Type',
    downloadUrl: 'Download URL',
    type: 'Type',
    contactType: 'Contact Type',
    socialMedia: 'Social Media',
    sortOption: 'Sort Option',
    tabName: 'Tab Name'
  },
  propertyValue: {
    exhibitors: 'exhibitors'
  },
  element: {
    logo: 'logo',
    exhibitorTitle: 'exhibitor title',
    description: 'description',
    brands: 'brands',
    viewAllProducts: 'view all products',
    mainStandHolderTitle: 'main stand holder title',
    showObjective: 'show objective',
    filterTag: 'filter tag',
    goldCarousel: 'gold carousel',
    productTitle: 'product title',
    productImage: 'product image',
    overlayProductLink: 'overlay product link',
    sharerTitle: 'sharer title'
  },
  contactType: {
    website: 'website',
    phone: 'phone',
    email: 'email',
    socialMedia: 'social media',
    accompanyingWebsite: 'accompanying website'
  },
  tabName: {
    documents: 'documents',
    products: 'products'
  }
};
